import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaUserEdit } from "react-icons/fa";
import { Button, SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux

import { ShimmerTable } from "react-shimmer-effects";
import AddBadgeModal from "../../Components/Modals/AddBadgeModal";
import { useGetAllBadgesQuery } from "../../Data/services/badgesApi";

const BadgesManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [viewModal, setViewModal] = useState(false);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllBadgesQuery({ refetchOnMountOrArgChange: true });

  var badgesData = useSelector((state) => state.badges.badges);
  // console.log(`badgesData = ${badgesData}`);

  const handleViewModalOpen = () => {
    setViewModal(true);
  };

  const handleViewModalClose = () => {
    setViewModal(false);
    setEditedData("");
  };

  const handleAddBadge = () => {
    setViewModal(true);
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    // return indicationMessage;
    return <ShimmerTable row={5} col={3} />;
  }

  const columns = [
    {
      dataField: "variants",
      text: "Badges",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (collContent, row) => {
        if (row.slug) {
          // console.log(row.variants.length);
          return (
            <div
              className="d-flex flex-wrap justify-content-center"
              key={row.slug}
            >
              {row.variants.map((data, index) => {
                return (
                  <img
                    key={index}
                    alt={data.title}
                    src={data.image_url}
                    width={100}
                    className="m-2 rounded-5"
                  />
                );
              })}
            </div>
          );
        }
      },
    },
    {
      dataField: "title",
      text: "Badge Name",
      sort: true,
      headerAlign: "center",
      align: "left",
      headerStyle: {
        width: "100px",
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center rounded-3 mx-2 p-2 `}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleViewModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1 `}
                >
                  <FaUserEdit />
                </span>
                <span>Edit</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {viewModal ? (
        <AddBadgeModal
          handleModalClose={handleViewModalClose}
          data={editedData}
          refetch={refetch}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Badges Management"} />
        </div>

        <Row>
          <Col md={12}>
            <Button
              classes={`${styles.cardBtn} `}
              text="Add Badge"
              onClick={handleAddBadge}
            ></Button>
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={badgesData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default BadgesManagementScreen;
