import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import { Button } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";

const EditUserModal = ({ handleEditModalClose, data, handleUpdate }) => {
  const { handleSubmit, control } = useForm({});

  const [userSlug, setUserSlug] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const statusList = [
    { label: "Active", value: "Active" },
    { label: "Block", value: "Block" },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    console.log(data);
    if (data) {
      setUserSlug(data.slug);
      setUserName(data.name);
      setUserEmail(data.email);
      if (data.is_blocked === true) {
        setSelectedStatus("Block");
      } else {
        setSelectedStatus("Active");
      }
    }
  }, [data, mounted]);

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">
              {" "}
              {data ? "Edit User" : "Add User"}{" "}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form onSubmit={handleSubmit(handleUpdate)}>
              {/* <Row>
                <Col md={12}>
                  <p className="m-0">User Name</p>
                  <Controller
                    control={control}
                    name="name"
                    rules={{}}
                    defaultValue={userName}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mt-2 mb-3"
                        type="text"
                        placeholder="Enter user name"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
              </Row> */}
              {/* {data ? (
                ""
              ) : (
                <Row>
                  <Col md={12}>
                    <p className="m-0">User Email</p>
                    <Input
                      classes="mt-2 mb-3"
                      type="email"
                      placeholder="Enter user email"
                      value={userEmail}
                      onChange={handleUserEmail}
                    />
                  </Col>
                </Row>
              )} */}
              {/* {data ? (
                ""
              ) : (
                <Row>
                  <Col md={12}>
                    <p className="m-0">Password</p>
                    <Input
                      classes="mt-2 mb-3"
                      type="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={handlePassword}
                    />
                  </Col>
                </Row>
              )} */}
              {/* <Row>
              <Col md={12}>
                <p className="m-0">Role</p>
                <SelectFieldComponent
                  firstOption={
                    _.isEmpty(selectedRole) ? "Select Role" : selectedRole
                  }
                  optionsList={roleList}
                  onSelectChange={handleRoleChange}
                />
              </Col>
            </Row> */}
              <Row>
                <Col md={12}>
                  <p className="m-0">Status</p>
                  <Controller
                    control={control}
                    name="is_blocked"
                    rules={{}}
                    defaultValue={selectedStatus}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <SelectFieldComponent
                        firstOption={
                          _.isEmpty(selectedStatus)
                            ? "Select Status"
                            : selectedStatus
                        }
                        optionsList={statusList}
                        onSelectChange={onChange}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 `}
                    text={data ? "Update" : "Save"}
                    type="submit"
                    // onClick={handleSubmit}
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditUserModal;
