import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaEye } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import {
  useDeletePostMutation,
  useGetAllPostsQuery,
} from "../../Data/services/postApi";

import moment from "moment/moment";
import { ShimmerTable } from "react-shimmer-effects";
import ViewPostModal from "../../Components/Modals/ViewPostModal";
import Toast, { SuccessToast } from "../../Hooks/useToast";

const PostManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  // const dummyData = [
  //   {
  //     id: 1,
  //     post_title: "Devil Times Five (a.k.a. Peopletoys)",
  //     video_url: "https://www.youtube.com/watch?v=HJFxTsX0Ok0",
  //     user_name: "adranfield0",
  //     date: "6/29/2023",
  //   },
  //   {
  //     id: 2,
  //     post_title: "Young Lions, The",
  //     video_url: "https://www.youtube.com/watch?v=HJFxTsX0Ok0",
  //     user_name: "milett1",
  //     date: "2/14/2024",
  //   },
  //   {
  //     id: 3,
  //     post_title: "Deep Red (Profondo rosso)",
  //     video_url: "https://www.youtube.com/watch?v=HJFxTsX0Ok0",
  //     user_name: "dtomblett2",
  //     date: "12/15/2023",
  //   },
  //   {
  //     id: 4,
  //     post_title: "Hunchback of Notre Dame, The",
  //     video_url: "Drama|Horror",
  //     user_name: "jmassimi3",
  //     date: "2/19/2024",
  //   },
  //   {
  //     id: 5,
  //     post_title: "Dahmer",
  //     video_url: "Drama|Horror|Thriller",
  //     user_name: "bposen4",
  //     date: "10/19/2023",
  //   },
  //   {
  //     id: 6,
  //     post_title: "London",
  //     video_url: "Documentary",
  //     user_name: "ivany5",
  //     date: "10/21/2023",
  //   },
  //   {
  //     id: 7,
  //     post_title: "Thief of Bagdad, The",
  //     video_url: "Action|Adventure|Fantasy",
  //     user_name: "cstaniforth6",
  //     date: "8/6/2023",
  //   },
  //   {
  //     id: 8,
  //     post_title: "Grass Harp, The",
  //     video_url: "Comedy|Drama",
  //     user_name: "rgownge7",
  //     date: "11/5/2023",
  //   },
  //   {
  //     id: 9,
  //     post_title: "Goldfish Memory",
  //     video_url: "Comedy|Drama",
  //     user_name: "mrosle8",
  //     date: "12/5/2023",
  //   },
  //   {
  //     id: 10,
  //     post_title: "Not as a Stranger",
  //     video_url: "Drama",
  //     user_name: "mkubas9",
  //     date: "2/3/2024",
  //   },
  //   {
  //     id: 11,
  //     post_title: "Ballou",
  //     video_url: "Documentary|Musical",
  //     user_name: "gmckoya",
  //     date: "3/26/2024",
  //   },
  //   {
  //     id: 12,
  //     post_title: "Tumannost Andromedy",
  //     video_url: "Adventure|Romance|Sci-Fi",
  //     user_name: "smatyukonb",
  //     date: "12/21/2023",
  //   },
  //   {
  //     id: 13,
  //     post_title: "Vivien Leigh: Scarlett and Beyond",
  //     video_url: "Documentary",
  //     user_name: "leaglestonc",
  //     date: "2/18/2024",
  //   },
  //   {
  //     id: 14,
  //     post_title: "Machuca",
  //     video_url: "Drama",
  //     user_name: "bcuddehayd",
  //     date: "11/7/2023",
  //   },
  //   {
  //     id: 15,
  //     post_title: "Amuck!",
  //     video_url: "Drama|Horror|Mystery",
  //     user_name: "ewestropee",
  //     date: "7/22/2023",
  //   },
  //   {
  //     id: 16,
  //     post_title: "Breakout",
  //     video_url: "Action|Adventure",
  //     user_name: "igongf",
  //     date: "7/24/2023",
  //   },
  //   {
  //     id: 17,
  //     post_title: "Zero Effect",
  //     video_url: "Comedy|Mystery|Thriller",
  //     user_name: "epetrollig",
  //     date: "4/29/2024",
  //   },
  //   {
  //     id: 18,
  //     post_title: "Finding Neverland",
  //     video_url: "Drama",
  //     user_name: "zfoxallh",
  //     date: "12/12/2023",
  //   },
  //   {
  //     id: 19,
  //     post_title: "Battle of the Rails, The (La bataille du rail)",
  //     video_url: "Drama|War",
  //     user_name: "kpittendreighi",
  //     date: "9/12/2023",
  //   },
  //   {
  //     id: 20,
  //     post_title: "Line King: The Al Hirschfeld Story, The",
  //     video_url: "Documentary",
  //     user_name: "cnovotnij",
  //     date: "7/5/2023",
  //   },
  // ];

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllPostsQuery({ refetchOnMountOrArgChange: true });
  // console.log(data);

  var allPosts = useSelector((state) => state.posts.posts);
  // console.log(`allPosts = ${allPosts}`);

  useEffect(() => {
    setRowData(allPosts);
  }, [allPosts, data]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const [deletePost] = useDeletePostMutation();

  const deleteData = async (id) => {
    // console.log(editedData.slug);
    // console.log(id);

    const postSlug = editedData.slug;

    try {
      const { data } = await deletePost(postSlug);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const handleViewModalOpen = () => {
    setViewModal(true);
  };

  const handleViewModalClose = () => {
    setViewModal(false);
    setEditedData("");
  };

  // const handleAddUser = () => {
  //   setViewModal(true);
  // };

  function indication() {
    // return something here
    // return 'Table is empty';
    // return indicationMessage;
    return <ShimmerTable row={5} col={4} />;
  }

  const columns = [
    {
      dataField: "caption",
      text: "Post Caption",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },

    {
      dataField: "user_name",
      text: "User",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.createdAt) {
          return (
            <abbr
              title={moment(row.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            >
              {moment(row.createdAt).fromNow()}
            </abbr>
          );
        }
      },
    },

    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row);
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center rounded-3 mx-2 p-2 `}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleViewModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1 `}
                >
                  <FaEye />
                </span>
                <span>View</span>
              </div>
              <div
                className="d-flex justify-content-center align-items-center bg-danger rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
        />
      ) : (
        ""
      )}

      {viewModal ? (
        <ViewPostModal
          handleModalClose={handleViewModalClose}
          data={editedData}
          modalTitle="View Post"
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Post Management"} />
        </div>
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default PostManagementScreen;
