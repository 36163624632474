import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import { useSendNotificationMutation } from "../../Data/services/notificationApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/sendNotificationScreen.module.scss";

const SendNotificationScreen = ({ setPage }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [sendNotification, { isError, isLoading, isSuccess }] =
    useSendNotificationMutation();

  const handleSendNotification = async (data) => {
    console.log(data);
    try {
      const response = await sendNotification(data);
      console.log(response.error);

      if (response.error && response.error.data.code === 401) {
        Toast(response?.error?.data?.message, "error");
      }

      if (!response.error && response.data.code === 200) {
        SuccessToast(response?.data?.message);
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  return (
    <div className={styles.MainContainer}>
      {errors
        ? errors.title?.message
          ? Toast(errors.title?.message, "error")
          : Toast(errors.message?.message, "error")
        : null}
      <Form onSubmit={handleSubmit(handleSendNotification)}>
        <div className={styles.headerContainer}>
          <div>
            <SubTitle text={"Send Notification"} />
          </div>
          <Row>
            <Col md={12}>
              <Button
                classes={`${styles.cardBtn} ${
                  isLoading ? "bg-dark-subtle" : ""
                }`}
                text={isLoading ? <BeatLoader size={10} /> : "Send"}
                onClick={handleSubmit(handleSendNotification)}
                disabled={isLoading}
              ></Button>
            </Col>
          </Row>
        </div>

        <div className="mt-3">
          <Controller
            control={control}
            name="title"
            rules={{
              required: "Title is required.",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputGroup className="mb-3">
                <Input
                  placeholder="Title"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              </InputGroup>
            )}
          />

          <Controller
            control={control}
            name="message"
            rules={{
              required: "Message is required.",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputGroup className="mb-3">
                <Input
                  placeholder="Notification"
                  type="textarea"
                  as="textarea"
                  rows={5}
                  value={value}
                  onChange={onChange}
                />
              </InputGroup>
            )}
          />
        </div>
      </Form>
    </div>
  );
};

export default SendNotificationScreen;
