import { createSlice } from "@reduxjs/toolkit";
import { workoutApi } from "../services/workoutApi";

const initialState = {
  workout: null,
};

export const workoutSlice = createSlice({
  name: "workout",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        workoutApi.endpoints.getAllWorkouts.matchFulfilled,
        (state, { payload }) => {
          state.workout = payload.data;
        }
      )
      .addMatcher(
        workoutApi.endpoints.getAllWorkouts.matchRejected,
        (state, { payload }) => {
          state.workout = null;
        }
      );
  },
});

export const {} = workoutSlice.actions;

export default workoutSlice.reducer;
