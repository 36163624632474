import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { useSelector } from "react-redux"; // for getting and setting data into local redux

import moment from "moment";
import { ShimmerTable } from "react-shimmer-effects";
import { useGetLogsQuery } from "../../Data/services/trainingLogApi";

const TrainingLogManagementScreen = ({ setPage }) => {
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);

  const { data, isSuccess, isLoading, isFetching, refetch } = useGetLogsQuery({
    refetchOnMountOrArgChange: true,
  });

  var logsData = useSelector((state) => state.logs.logs);
  console.log(`logsData = ${logsData}`);

  useEffect(() => {
    setRowData(logsData);
  }, [logsData]);

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={2} />;
  }

  const columns = [
    {
      dataField: "name",
      text: "User",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },

    {
      dataField: "duration",
      text: "Total Log Time",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.slug) {
          // Given time in decimal hours
          const decimalHours = row.duration;
          // Convert decimal hours to milliseconds
          const milliseconds = decimalHours * 60 * 60 * 1000;
          // Use Moment.js to format the duration
          const humanReadable = moment.duration(milliseconds).humanize();
          console.log(humanReadable);
          return humanReadable;
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Training Log Management"} />
        </div>
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default TrainingLogManagementScreen;
