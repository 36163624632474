import React from "react";
import { Description } from "../CustomComponents";

const UserAvatarWithNameAndTag = ({ avatarImage, userName, userTag }) => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-end ">
      <div className="d-flex">
        {avatarImage ? (
          <img className="image-circle" src={avatarImage} alt="" width={50} />
        ) : (
          ""
        )}
        <div className="d-flex flex-column mx-2">
          <Description classes={`fw-bold `} text={userName} />

          {userTag ? (
            <Description classes={`fs-8 gray-text`} text={userTag} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserAvatarWithNameAndTag;
