import { createSlice } from "@reduxjs/toolkit";
import { feedbackApi } from "../services/feedbackApi";

const initialState = {
  feedbacks: null,
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        feedbackApi.endpoints.getFeedback.matchFulfilled,
        (state, { payload }) => {
          state.feedbacks = payload.data;
        }
      )
      .addMatcher(
        feedbackApi.endpoints.getFeedback.matchRejected,
        (state, { payload }) => {
          state.feedbacks = null;
        }
      );
  },
});

export const {} = feedbackSlice.actions;

export default feedbackSlice.reducer;
