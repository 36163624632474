import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import ReactPlayer from "react-player";
import {
  useDeletePostCommentMutation,
  useGetPostCommentsQuery,
} from "../../Data/services/postApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import Comment from "../Comment/comment";
import { Description, SubTitle } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import CommentShimmer from "../Shimmers/comment_shimmer";
import UserAvatarWithNameAndTag from "../UserAvatar/UserAvatarWithNameAndTag";
import cssStyles from "./styles/viewModal.module.scss";

const ViewPostModal = ({ handleModalClose, data, modalTitle }) => {
  const postId = data.slug;
  const [comments, setComments] = useState([]);

  const {
    data: commentData,
    isSuccess,
    isLoading,
    isFetching,
    refetch,
  } = useGetPostCommentsQuery(postId);

  // console.log(postId);
  // console.log(commentData);

  useEffect(() => {
    if (commentData) {
      console.log(commentData.data);
      setComments(commentData.data);
    }
  }, [commentData]);

  const [deleteComment] = useDeletePostCommentMutation();

  const handleCommentDelete = async (slug) => {
    console.log(slug);

    try {
      const { data } = await deleteComment(slug);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  return (
    <div>
      <ModalContainer handleClose={handleModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0"> {modalTitle} </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>

          <Row className="w-100 h-100">
            <Col md={6} sm={12} className="">
              <div className="d-flex align-items-start w-100 h-100">
                {data.video_url ? (
                  <ReactPlayer
                    url={data.video_url}
                    width="100%"
                    controls={true}
                  />
                ) : null}
                {data.image_url ? (
                  <img
                    src={data.image_url}
                    alt=""
                    width="100%"
                    className={`${cssStyles.workoutImage}`}
                  />
                ) : null}
              </div>
            </Col>
            <Col md={6} sm={12} className="">
              <div className="d-flex flex-column justify-content-start align-items-top w-100 h-100">
                <SubTitle classes="lc-1" text={data.post_title} />

                <Description
                  classes="my-1 gray-text"
                  text={`Published date: ${moment(data.createdAt).format(
                    "MMMM Do YYYY"
                  )}`}
                />

                <div className="d-flex flex-row justify-content-between align-items-center">
                  <UserAvatarWithNameAndTag
                    avatarImage={data.user_image}
                    userName={data.user_name}
                    // userTag={"@username"}
                  />

                  <SubTitle
                    text={data.price === null ? "Free" : "$ " + data.price}
                  />
                </div>

                <Description classes="my-2" text={data.caption} />

                <hr></hr>

                <SubTitle classes="lc-1 fs-5 mb-4" text="Comments" />

                {/* Shimmer */}
                {isLoading ? <CommentShimmer /> : ""}

                {isSuccess
                  ? comments.length > 0
                    ? comments.map((comment, i) => {
                        // console.log(comment);
                        // console.log(i);
                        return (
                          <>
                            <Comment
                              userName={comment.name}
                              // tag="@abcdefghijk"
                              avatarImage={comment.image_url}
                              commentTime={moment(comment.createdAt).fromNow()}
                              commentText={comment.comment}
                              commentSlug={comment.slug}
                              onCommentDelete={handleCommentDelete}
                            />
                            {i <= 3 ? <hr></hr> : ""}
                          </>
                        );
                      })
                    : "No Comment Found."
                  : ""}
              </div>
            </Col>
          </Row>
        </div>
      </ModalContainer>
    </div>
  );
};

export default ViewPostModal;
