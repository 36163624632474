import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import {
  useDeleteForumCommentMutation,
  useGetForumCommentsQuery,
} from "../../Data/services/forumApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import Comment from "../Comment/comment";
import { Description, SubTitle } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import CommentShimmer from "../Shimmers/comment_shimmer";
import UserAvatarWithNameAndTag from "../UserAvatar/UserAvatarWithNameAndTag";
import cssStyles from "./styles/viewModal.module.scss";

const ViewForumModal = ({ handleModalClose, data, modalTitle }) => {
  const forumId = data.slug;
  const [comments, setComments] = useState([]);

  const {
    data: commentData,
    isSuccess,
    isLoading,
    isFetching,
    refetch,
  } = useGetForumCommentsQuery(forumId);

  useEffect(() => {
    if (commentData) {
      console.log(commentData.data);
      setComments(commentData.data);
    }
  }, [commentData]);

  const [deleteComment] = useDeleteForumCommentMutation();

  const handleCommentDelete = async (slug) => {
    console.log(slug);

    try {
      const { data } = await deleteComment(slug);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  return (
    <div>
      <ModalContainer handleClose={handleModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0"> {modalTitle} </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>

          <Row className="w-100 h-100">
            <Col md={6} sm={12} className="">
              <div className="d-flex flex-column align-items-start w-100 h-100">
                <div className="d-flex flex-row justify-content-between align-items-center my-2">
                  <UserAvatarWithNameAndTag
                    avatarImage={"images/user_image.png"}
                    userName={"User Name"}
                    userTag={"@username"}
                  />
                </div>

                <SubTitle classes="lc-1" text={data.title} />

                <Description
                  classes="my-1 gray-text fs-75"
                  text={`Published date: ${moment(data.createdAt).format(
                    "MMMM Do YYYY"
                  )}`}
                />

                {data.media_url ? (
                  <img
                    src={data.media_url}
                    alt=""
                    width="200px"
                    style={{ borderRadius: "25px" }}
                  />
                ) : null}

                <Description classes="my-2 gray-text" text={data.description} />
              </div>
            </Col>
            <Col md={6} sm={12} className="">
              <div className="d-flex flex-column justify-content-start align-items-top w-100 h-100">
                <SubTitle
                  classes="lc-1 fs-5 mb-4"
                  text={`Comments (${data.comments})`}
                />

                {isLoading ? <CommentShimmer /> : ""}

                {isSuccess
                  ? comments.length > 0
                    ? comments.map((comment, i) => {
                        // console.log(comment);
                        // console.log(i);
                        return (
                          <>
                            <Comment
                              userName={comment.name}
                              // tag="@abcdefghijk"
                              avatarImage={comment.image_url}
                              commentTime={moment(comment.createdAt).fromNow()}
                              commentText={comment.comment}
                              commentSlug={comment.slug}
                              onCommentDelete={handleCommentDelete}
                            />
                            {i <= 3 ? <hr></hr> : ""}
                          </>
                        );
                      })
                    : "No Comment Found."
                  : ""}
              </div>
            </Col>
          </Row>
        </div>
      </ModalContainer>
    </div>
  );
};

export default ViewForumModal;
