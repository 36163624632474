import { createSlice } from "@reduxjs/toolkit";
import { badgesApi } from "../services/badgesApi";

const initialState = {
  badges: null,
};

export const badgeSlice = createSlice({
  name: "badge",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        badgesApi.endpoints.getAllBadges.matchFulfilled,
        (state, { payload }) => {
          state.badges = payload.data;
        }
      )
      .addMatcher(
        badgesApi.endpoints.getAllBadges.matchRejected,
        (state, { payload }) => {
          state.badges = null;
        }
      );
  },
});

export const {} = badgeSlice.actions;

export default badgeSlice.reducer;
