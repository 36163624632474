import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
// import * as XLSX from "xlsx";
import { Button, SubTitle } from "../../Components/CustomComponents";
import styles from "./styles/user_management.module.scss";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { ShimmerTable } from "react-shimmer-effects";
import BeatLoader from "react-spinners/BeatLoader";
import Table from "../../Components/Table/Table";
import {
  useGetSportsListingQuery,
  useUpdateSportsListingMutation,
} from "../../Data/services/sportsListingApi";

const SportsListingScreen = ({ setPage }) => {
  const dispatch = useDispatch();
  const [excelData, setExcelData] = useState([]);

  const inputFile = useRef(null);

  const [indicationMessage, setIndicationMessage] = useState(
    "Upload excel file..."
  ); //todo:
  const [rowData, setRowData] = useState([]);

  const handleOpenFileUploader = () => {
    inputFile.current.click();
  };

  const [uploadFile, { isLoading: isUploading }] =
    useUpdateSportsListingMutation();

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("sports", file);

    uploadFile(formData);
  };

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetSportsListingQuery({ refetchOnMountOrArgChange: true });
  console.log(`data = ${data}`);

  var sportsListingData = useSelector(
    (state) => state.sportsListing.sportsListing
  );
  console.log(`sportsListingData = ${sportsListingData}`);

  useEffect(() => {
    if (sportsListingData != null) {
      setRowData(sportsListingData);
    }
  }, [sportsListingData, data]);

  const columns = [
    {
      dataField: "title",
      text: "Sports Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
  ];

  function indication() {
    // return something here
    // return 'Table is empty';
    // return isSuccess ? indicationMessage : "Data fetching...";
    return <ShimmerTable row={10} col={1} />;
  }

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Sports Listing"} />
        </div>
        <Row>
          <Col md={12}>
            <input
              type="file"
              id="file"
              ref={inputFile}
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
            <Button
              classes={`${styles.cardBtn} `}
              text={
                isUploading ? <BeatLoader size={10} /> : "Import Excel Sheet"
              }
              onClick={handleOpenFileUploader}
              disabled={isUploading}
            ></Button>
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        <Table
          // data={_.flatten(excelData)}
          data={isSuccess ? rowData : []}
          columns={columns}
          indication={indication}
        />
      </div>
    </div>
  );
};

export default SportsListingScreen;
