import axios from "axios";
import React, { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import Toast from "../../Hooks/useToast";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { useDispatch } from "react-redux"; // for getting and setting data into local redux
// import { useGetAllUsersQuery } from "../../Data/services/userApi"; // get data from API Servers

import ViewPostModal from "../../Components/Modals/ViewPostModal";

const EarningManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const dummyData = [
    {
      id: 1,
      total_earning: "$ 100",
      transaction_id: "tx_1234567890",
      transaction_date: "15-5-2024",
      user_name: "adranfield0",
    },
    {
      id: 2,
      total_earning: "$ 200",
      transaction_id: "tx_1234567890",
      transaction_date: "15-5-2024",
      user_name: "adranfield1",
    },
    {
      id: 3,
      total_earning: "$ 300",
      transaction_id: "tx_1234567890",
      transaction_date: "15-5-2024",
      user_name: "adranfield3",
    },
    {
      id: 4,
      total_earning: "$ 400",
      transaction_id: "tx_1234567890",
      transaction_date: "15-5-2024",
      user_name: "adranfield4",
    },
    {
      id: 5,
      total_earning: "$ 500",
      transaction_id: "tx_1234567890",
      transaction_date: "15-5-2024",
      user_name: "adranfield5",
    },
    {
      id: 6,
      total_earning: "$ 600",
      transaction_id: "tx_1234567890",
      transaction_date: "15-5-2024",
      user_name: "adranfield6",
    },
  ];

  // const { data, isSuccess, isLoading, isFetching, refetch } =
  //   useGetAllUsersQuery({ refetchOnMountOrArgChange: true });

  // var userData = useSelector((state) => state.user.users);
  // // console.log(`userData = ${userData}`);
  // useEffect(() => {
  //   setRowData(userData);
  // }, [userData]);

  // useEffect(() => {
  //   if (data && isSuccess) {
  //     // console.log("isSuccess = " + data.users);
  //     dispatch(updateUsers(data.users));
  //   }
  // }, [isSuccess, data, dispatch]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const deleteData = (id) => {
    console.log(id);
    axios(`https://jsonplaceholder.typicode.com//posts/${id}`)
      .then((res) => {
        // console.log(res.data)
        // console.log(res.status)
        // setData(res.data);
        if (res.status === 200) {
          Toast("Deleted Successfully", "success");
          handleDeleteModalClose();
        }
      })
      .catch((e) => {
        console.log(e);
        console.log(e.message);
        Toast("Something went wrong.", "error");
        // handleDeleteModalClose();
        // setIndicationMessage("Something went wrong, refresh again");
      });
  };

  const handleViewModalOpen = () => {
    setViewModal(true);
  };

  const handleViewModalClose = () => {
    setViewModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setViewModal(true);
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return indicationMessage;
  }

  const columns = [
    {
      dataField: "user_name",
      text: "User",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "transaction_id",
      text: "Txn ID",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "transaction_date",
      text: "Txn Date",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "total_earning",
      text: "Total Earning",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
    },

    // {
    //   dataField: "actions",
    //   text: "Actions",
    //   headerAlign: "center",
    //   align: 'center',
    //   headerStyle: {
    //     width: '200px',
    //   },
    // formatter: (cellContent, row) => {
    //   // console.log(row)
    //   if (row.id) {
    //     return (
    //       <div className="d-flex justify-content-center align-items-center ">
    //         <div
    //           className={`${styles.btnBg} d-flex justify-content-center align-items-center rounded-3 mx-2 p-2 `}
    //           role="button"
    //           onClick={() => {
    //             setEditedData(row);
    //             handleViewModalOpen(true);
    //           }}
    //         >
    //           <span
    //             className={`align-items-center d-flex justify-content-center pe-1 `}
    //           >
    //             <FaEye />
    //           </span>
    //           <span>View</span>
    //         </div>
    //       </div>
    //     );
    //   }
    // },
    // },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
        />
      ) : (
        ""
      )}

      {viewModal ? (
        <ViewPostModal
          handleModalClose={handleViewModalClose}
          data={editedData}
          modalTitle="View Workout"
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"EARNING Management"} />
        </div>

        <div
          className=" rounded-3 px-2 py-1"
          style={{ border: "1px solid white" }}
        >
          <SubTitle text={"Total Earning: $2100"} />
        </div>
      </div>

      <div className={styles.mainTable}>
        {<Table data={dummyData} columns={columns} indication={indication} />}
      </div>
    </div>
  );
};

export default EarningManagementScreen;
