import { baseApi } from "./baseApi";
import { getToken } from "./localStorageService";

export const sportsListingApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    updateSportsListing: builder.mutation({
      query: (body) => {
        return {
          url: "sports",
          method: "POST",
          body,
          formData: true,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      invalidatesTags: ["sportsListing"],
    }),
    getSportsListing: builder.query({
      query: () => {
        return {
          url: "sports",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
      providesTags: ["sportsListing"],
    }),
  }),
});

export const { useGetSportsListingQuery, useUpdateSportsListingMutation } =
  sportsListingApi;
