import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// Or from '@reduxjs/toolkit/query/react';
// import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from "./Data/features/authSlice";
import badgesReducer from "./Data/features/badgeSlice";
import dashboardSlice from "./Data/features/dashboardSlice";
import feedbackReducer from "./Data/features/feedbackSlice";
import forumReducer from "./Data/features/forumSlice";
import postReportReducer from "./Data/features/postReportSlice";
import postsReducer from "./Data/features/postsSlice";
import settingReducer from "./Data/features/settingSlice";
import sportsListingSlice from "./Data/features/sportsListingSlice";
import templatesReducer from "./Data/features/templateSlice";
import trainingLogReducer from "./Data/features/trainingLogSlice";
import userReducer from "./Data/features/userSlice";
import workoutReducer from "./Data/features/workoutSlice";

// rtk query api
import { badgesApi } from "./Data/services/badgesApi";
import { dashboardApi } from "./Data/services/dashboardApi";
import { feedbackApi } from "./Data/services/feedbackApi";
import { forumApi } from "./Data/services/forumApi";
import { notificationApi } from "./Data/services/notificationApi";
import { postApi } from "./Data/services/postApi";
import { postReportApi } from "./Data/services/postReportApi";
import { settingApi } from "./Data/services/settingApi";
import { sportsListingApi } from "./Data/services/sportsListingApi";
import { templatesApi } from "./Data/services/templatesApi";
import { trainingLogApi } from "./Data/services/trainingLogApi";
import { userApi } from "./Data/services/userApi";
import { userAuthApi } from "./Data/services/userAuthApi";
import { workoutApi } from "./Data/services/workoutApi";
// Add the generated reducer as a specific top-level slice
import sessionMiddleware from "./Data/middleware/sessionMiddleware";

export const store = configureStore({
  reducer: {
    // RTK Query API

    [userAuthApi.reducerPath]: userAuthApi.reducer,
    [settingApi.reducerPath]: settingApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [workoutApi.reducerPath]: workoutApi.reducer,
    [forumApi.reducerPath]: forumApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [sportsListingApi.reducerPath]: sportsListingApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [trainingLogApi.reducerPath]: trainingLogApi.reducer,
    [feedbackApi.reducerPath]: feedbackApi.reducer,
    [postReportApi.reducerPath]: postReportApi.reducer,
    [badgesApi.reducerPath]: badgesApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,

    // Local storage Redux
    user: userReducer,
    authInfo: authReducer,
    dashboard: dashboardSlice,
    sportsListing: sportsListingSlice,
    posts: postsReducer,
    workouts: workoutReducer,
    forums: forumReducer,
    setting: settingReducer,
    logs: trainingLogReducer,
    feedbacks: feedbackReducer,
    postReports: postReportReducer,
    badges: badgesReducer,
    templates: templatesReducer,
  },
  // load from local storage
  preloadedState: loadFromLocalStorage(), //todo:

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  //todo:
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userAuthApi.middleware,
      settingApi.middleware,
      userApi.middleware,
      postApi.middleware,
      workoutApi.middleware,
      forumApi.middleware,
      dashboardApi.middleware,
      sportsListingApi.middleware,
      notificationApi.middleware,
      trainingLogApi.middleware,
      feedbackApi.middleware,
      postReportApi.middleware,
      badgesApi.middleware,
      templatesApi.middleware,
      sessionMiddleware, // for session check each api response
    ]),
});

//todo:
function saveToLocalStorage(state) {
  try {
    const serialState = JSON.stringify(state);
    localStorage.setItem("reduxStore", serialState);
  } catch (e) {
    console.warn(e);
  }
}

//todo:
function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem("reduxStore");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

//todo:
store.subscribe(() =>
  saveToLocalStorage({ authInfo: store.getState().authInfo })
);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch); //todo:
