import React from "react";
import { Description } from "../CustomComponents";
import UserAvatarWithNameAndTag from "../UserAvatar/UserAvatarWithNameAndTag";

const Comment = ({
  userName,
  avatarImage,
  tag,
  commentText,
  commentTime,
  commentSlug,
  onCommentDelete,
}) => {
  return (
    <div className="d-flex flex-column justify-content-start align-items-top">
      <div className="d-flex flex-row justify-content-between align-items-end">
        <UserAvatarWithNameAndTag
          avatarImage={avatarImage}
          userName={userName}
          userTag={tag}
        />

        <Description classes={`fs-8 gray-text`} text={commentTime} />
      </div>

      <div>
        <Description classes="my-2 text-gray " text={commentText} />
      </div>
      <div className="d-flex justify-content-end align-items-center w-100">
        <div
          className="rounded-3 p-2 bg-danger text-center"
          style={{ width: "100px" }}
          role="button"
          onClick={() => {
            onCommentDelete(commentSlug);
          }}
        >
          <span className={`mx-2`}>Delete</span>
        </div>
      </div>
    </div>
  );
};

export default Comment;
