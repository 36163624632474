import { createSlice } from "@reduxjs/toolkit";
import { postApi } from "../services/postApi";

const initialState = {
  posts: null,
};

export const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        postApi.endpoints.getAllPosts.matchFulfilled,
        (state, { payload }) => {
          state.posts = payload.data;
        }
      )
      .addMatcher(
        postApi.endpoints.getAllPosts.matchRejected,
        (state, { payload }) => {
          state.posts = null;
        }
      );
  },
});

export const {} = postsSlice.actions;

export default postsSlice.reducer;
