import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import {
  useAddBadgeMutation,
  useUpdateBadgeMutation,
} from "../../Data/services/badgesApi";
import Toast, { ErrorToast, SuccessToast } from "../../Hooks/useToast";
import { Button, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import DraggableImageUploader from "../DraggableImageUpload/DraggableImageUploader";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/addBadgeModal.module.scss";

const AddBadgeModal = ({ handleModalClose, data, modalTitle, isAdding }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [badgeName, setBadgeName] = useState("");
  const [badgeImageV1, setBadgeImageV1] = useState("");
  const [badgeImageV2, setBadgeImageV2] = useState("");
  const [badgeImageV3, setBadgeImageV3] = useState("");
  const [badgeImageV4, setBadgeImageV4] = useState("");
  const [badgeImageV5, setBadgeImageV5] = useState("");

  const [selectedType, setSelectedType] = useState("");

  const typeList = [
    { label: "SOCIAL_SWEATER", value: "SOCIAL_SWEATER" },
    { label: "DEDICATION_DEMON", value: "DEDICATION_DEMON" },
    { label: "WORKOUT_WEAVER", value: "WORKOUT_WEAVER" },
    { label: "FITNESS_PHENOM", value: "FITNESS_PHENOM" },
    {
      label: "CERTIFIED_PERSONAL_TRAINER",
      value: "CERTIFIED_PERSONAL_TRAINER",
    },
    {
      label: "CERTIFIED_STRENGTH_AND_CONDITIONING_COACH",
      value: "CERTIFIED_STRENGTH_AND_CONDITIONING_COACH",
    },
    {
      label: "CERTIFIED_PHYSICAL_THERAPIST",
      value: "CERTIFIED_PHYSICAL_THERAPIST",
    },
    {
      label: "ATHLETE",
      value: "ATHLETE",
    },
    {
      label: "MASTER_OF_MODES",
      value: "MASTER_OF_MODES",
    },
    {
      label: "HEART_OF_THE_HUB",
      value: "HEART_OF_THE_HUB",
    },
    {
      label: "IM_THE_CONSISTENCY",
      value: "IM_THE_CONSISTENCY",
    },
    {
      label: "GYMFLUENCER",
      value: "GYMFLUENCER",
    },
  ];

  useEffect(() => {
    console.log(data);
    if (data) {
      setBadgeName(data.title);
      setBadgeImageV1(data.variants[0].image_url);
      setBadgeImageV2(data.variants[1].image_url);
      setBadgeImageV3(data.variants[2].image_url);
      setBadgeImageV4(data.variants[3].image_url);
      setBadgeImageV5(data.variants[4].image_url);
    }
  }, [data]);

  const [addBadge, { isError, isLoading, isSuccess }] = useAddBadgeMutation();

  const handleSubmit = async () => {
    if (isSubmitted) return;

    try {
      setIsSubmitted(true);

      const formData = new FormData();
      formData.append("title", badgeName);
      formData.append("type", selectedType);

      // Adding multiple variant names
      formData.append("variant_names", "Black & White");
      formData.append("variant_names", "Silver");
      formData.append("variant_names", "Gold");
      formData.append("variant_names", "Purple");
      formData.append("variant_names", "Colorful");

      // Adding multiple variant images
      formData.append("variant_images", badgeImageV1);
      formData.append("variant_images", badgeImageV2);
      formData.append("variant_images", badgeImageV3);
      formData.append("variant_images", badgeImageV4);
      formData.append("variant_images", badgeImageV5);

      // Log the FormData entries to see what is being sent
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      // Submit the formData
      const result = await addBadge(formData).unwrap();
      console.log(result);

      if (result.code === 200) {
        Toast(result.message, "success");
        handleModalClose();
      }
      setIsSubmitted(false);
    } catch (e) {
      if (e?.data?.code === 400) {
        ErrorToast(e.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  const [
    updateBadge,
    {
      isError: updateERror,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
    },
  ] = useUpdateBadgeMutation();

  // Function to check if the value is a URL
  const isUrl = (value) =>
    _.startsWith(value, "http://") || _.startsWith(value, "https://");

  const handleUpdate = async () => {
    if (isSubmitted) return;
    const badgeImages = [
      badgeImageV1,
      badgeImageV2,
      badgeImageV3,
      badgeImageV4,
      badgeImageV5,
    ];

    const variants_slugs = [
      data.variants[0].slug,
      data.variants[1].slug,
      data.variants[2].slug,
      data.variants[3].slug,
      data.variants[4].slug,
    ];

    let formIndex = 0;

    try {
      setIsSubmitted(true);

      const formData = new FormData();
      formData.append("title", badgeName);

      badgeImages.forEach((image, index) => {
        if (!isUrl(image)) {
          formData.append(`variant_id[${formIndex}]`, variants_slugs[index]);
          formData.append(`variant_images[${formIndex}]`, image);
          formIndex++;
        }
      });

      // // Log the FormData entries to see what is being sent
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}:`, value);
      // }

      // Submit the formData
      const slug = await data.slug;
      const result = await updateBadge({ formData, slug });
      // console.log(result);
      console.log(result.data.code);

      if (!result.data && result.error.status === 400) {
        ErrorToast(result.error.data?.message);
      }

      if (!result.error && result.data.code === 200) {
        SuccessToast(result.data.message, "success");
        handleModalClose();
      }
      setIsSubmitted(false);
    } catch (e) {
      if (e?.data?.code === 400) {
        ErrorToast(e.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  return (
    <div>
      <ModalContainer handleClose={handleModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">
              {data ? "Edit Badge" : "Add Badge"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>

          <div className="w-100 h-100 d-flex flex-row justify-content-start flex-wrap">
            <DraggableImageUploader
              key={1}
              mediaImage={badgeImageV1}
              setMediaImage={setBadgeImageV1}
              text={"Black & White"}
            />
            <DraggableImageUploader
              key={2}
              mediaImage={badgeImageV2}
              setMediaImage={setBadgeImageV2}
              text={"Silver"}
            />
            <DraggableImageUploader
              key={3}
              mediaImage={badgeImageV3}
              setMediaImage={setBadgeImageV3}
              text={"Gold"}
            />
            <DraggableImageUploader
              key={4}
              mediaImage={badgeImageV4}
              setMediaImage={setBadgeImageV4}
              text={"Purple"}
            />
            <DraggableImageUploader
              key={5}
              mediaImage={badgeImageV5}
              setMediaImage={setBadgeImageV5}
              text={"Colorful"}
            />
          </div>

          <div className="d-flex flex-column justify-content-start align-items-top w-100 h-100">
            <Input
              placeholder="Enter Badge Name"
              type="text"
              value={badgeName}
              onChange={(e) => {
                setBadgeName(e.target.value);
              }}
            />
            {data ? (
              ""
            ) : (
              <SelectFieldComponent
                firstOption={
                  _.isEmpty(selectedType) ? "Select Type" : selectedType
                }
                optionsList={typeList}
                onSelectChange={setSelectedType}
              />
            )}

            <Button
              classes="mt-3 mb-2"
              type="submit"
              text={
                isLoading ? (
                  <BeatLoader size={10} />
                ) : updateLoading ? (
                  <BeatLoader size={10} />
                ) : data ? (
                  "Update"
                ) : (
                  "Save"
                )
              }
              onClick={data ? handleUpdate : handleSubmit}
              disabled={isLoading || updateLoading}
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default AddBadgeModal;
