import React from "react";

import styles from "./styles/Dashboard.module.scss";

import { Col, Row } from "react-bootstrap";
import { SubTitle } from "../../Components/CustomComponents";
// import MatchCard from '../../Components/Cards/MatchCard';
// import TournamentCard from '../../Components/Cards/TournamentCard';
import { FaDatabase } from "react-icons/fa6";
import { useSelector } from "react-redux";
import DashboardCard from "../../Components/Cards/DashboardCard";
import { ROUTES } from "./constants";

export default function Dashboard({ setPage }) {
  const handleViewLiveMatches = () => {
    setPage(ROUTES.LIVE_MATCHES);
  };

  const handleViewTournament = () => {
    setPage(ROUTES.TOURNAMENTS);
  };

  //   const { data, isSuccess, isLoading, isFetching, refetch } =
  //     useGetDashboardDataQuery({ refetchOnMountOrArgChange: true });

  var dashboardData = useSelector((state) => state.dashboard.dashboardData);

  return (
    <div className={styles.Dashboard}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Dashboard"} />
        </div>
      </div>

      <Row className={`${styles.matchRow} w-100 mt-2 mb-5`}>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#86030387"}
            bg2={"#ff000088"}
            icon={<FaDatabase color="#860303" />}
            // number={isFetching ? 0 : dashboardData.post_count}
            number={dashboardData == null ? 0 : dashboardData.post_count}
            text={"Numbers of Posts"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#20267088"}
            bg2={"#b8366688"}
            icon={<FaDatabase color="#b83666" />}
            number={dashboardData == null ? 0 : dashboardData.workout_count}
            text={"Numbers of Workout"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#093e2288"}
            bg2={"#4eba3388"}
            icon={<FaDatabase color="#4eba33" />}
            number={dashboardData == null ? 0 : dashboardData.forum_count}
            text={"Numbers of Forum Post"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#e63e1988"}
            bg2={"#f5a51a88"}
            icon={<FaDatabase color="#f5a51a" />}
            number={dashboardData == null ? 0 : dashboardData.report_post_count}
            text={"Numbers of Reporter"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#0a4caa88"}
            bg2={"#3d87f488"}
            icon={<FaDatabase color="#3d87f4" />}
            number={dashboardData == null ? 0 : dashboardData.sports_count}
            text={"Numbers of Sports"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#0a4caa88"}
            bg2={"#3d87f488"}
            icon={<FaDatabase color="#3d87f4" />}
            number={dashboardData == null ? 0 : dashboardData.user_count}
            text={"Numbers of Users"}
          />
        </Col>
      </Row>
    </div>
  );
}
