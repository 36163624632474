import { createSlice } from "@reduxjs/toolkit";
import { templatesApi } from "../services/templatesApi";

const initialState = {
  templates: null,
};

export const templateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        templatesApi.endpoints.getAllTemplates.matchFulfilled,
        (state, { payload }) => {
          state.templates = payload.data;
        }
      )
      .addMatcher(
        templatesApi.endpoints.getAllTemplates.matchRejected,
        (state, { payload }) => {
          state.templates = null;
        }
      );
  },
});

export const {} = templateSlice.actions;

export default templateSlice.reducer;
