import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Description, SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import Toast from "../../Hooks/useToast";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux

import UserAvatarWithNameAndTag from "../../Components/UserAvatar/UserAvatarWithNameAndTag";

import ReactStars from "react-rating-stars-component";

import moment from "moment";
import { ShimmerTable } from "react-shimmer-effects";
import GlobalStyles from "../../_global.scss";
import { useGetFeedbackQuery } from "../../Data/services/feedbackApi";

const UserFeedbackManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  const { primaryColor } = GlobalStyles;

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const dummyData = [
    {
      id: 1,
      post_title: "Devil Times Five (a.k.a. Peopletoys)",
      image_url: "https://www.youtube.com/watch?v=HJFxTsX0Ok0",
      user_name: "adranfield0",
      date: "6/29/2023",
    },
    {
      id: 2,
      post_title: "Young Lions, The",
      image_url: "https://www.youtube.com/watch?v=HJFxTsX0Ok0",
      user_name: "milett1",
      date: "2/14/2024",
    },
    {
      id: 3,
      post_title: "Deep Red (Profondo rosso)",
      image_url: "https://www.youtube.com/watch?v=HJFxTsX0Ok0",
      user_name: "dtomblett2",
      date: "12/15/2023",
    },
    {
      id: 4,
      post_title: "Hunchback of Notre Dame, The",
      image_url: "Drama|Horror",
      user_name: "jmassimi3",
      date: "2/19/2024",
    },
    {
      id: 5,
      post_title: "Dahmer",
      image_url: "Drama|Horror|Thriller",
      user_name: "bposen4",
      date: "10/19/2023",
    },
    {
      id: 6,
      post_title: "London",
      image_url: "Documentary",
      user_name: "ivany5",
      date: "10/21/2023",
    },
    {
      id: 7,
      post_title: "Thief of Bagdad, The",
      image_url: "Action|Adventure|Fantasy",
      user_name: "cstaniforth6",
      date: "8/6/2023",
    },
    {
      id: 8,
      post_title: "Grass Harp, The",
      image_url: "Comedy|Drama",
      user_name: "rgownge7",
      date: "11/5/2023",
    },
    {
      id: 9,
      post_title: "Goldfish Memory",
      image_url: "Comedy|Drama",
      user_name: "mrosle8",
      date: "12/5/2023",
    },
    {
      id: 10,
      post_title: "Not as a Stranger",
      image_url: "Drama",
      user_name: "mkubas9",
      date: "2/3/2024",
    },
    {
      id: 11,
      post_title: "Ballou",
      image_url: "Documentary|Musical",
      user_name: "gmckoya",
      date: "3/26/2024",
    },
    {
      id: 12,
      post_title: "Tumannost Andromedy",
      image_url: "Adventure|Romance|Sci-Fi",
      user_name: "smatyukonb",
      date: "12/21/2023",
    },
    {
      id: 13,
      post_title: "Vivien Leigh: Scarlett and Beyond",
      image_url: "Documentary",
      user_name: "leaglestonc",
      date: "2/18/2024",
    },
    {
      id: 14,
      post_title: "Machuca",
      image_url: "Drama",
      user_name: "bcuddehayd",
      date: "11/7/2023",
    },
    {
      id: 15,
      post_title: "Amuck!",
      image_url: "Drama|Horror|Mystery",
      user_name: "ewestropee",
      date: "7/22/2023",
    },
    {
      id: 16,
      post_title: "Breakout",
      image_url: "Action|Adventure",
      user_name: "igongf",
      date: "7/24/2023",
    },
    {
      id: 17,
      post_title: "Zero Effect",
      image_url: "Comedy|Mystery|Thriller",
      user_name: "epetrollig",
      date: "4/29/2024",
    },
    {
      id: 18,
      post_title: "Finding Neverland",
      image_url: "Drama",
      user_name: "zfoxallh",
      date: "12/12/2023",
    },
    {
      id: 19,
      post_title: "Battle of the Rails, The (La bataille du rail)",
      image_url: "Drama|War",
      user_name: "kpittendreighi",
      date: "9/12/2023",
    },
    {
      id: 20,
      post_title: "Line King: The Al Hirschfeld Story, The",
      image_url: "Documentary",
      user_name: "cnovotnij",
      date: "7/5/2023",
    },
  ];

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetFeedbackQuery({ refetchOnMountOrArgChange: true });

  var userData = useSelector((state) => state.feedbacks.feedbacks);
  console.log(`userData = ${userData}`);
  useEffect(() => {
    setRowData(userData);
  }, [userData]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const deleteData = (id) => {
    console.log(id);
    axios(`https://jsonplaceholder.typicode.com//posts/${id}`)
      .then((res) => {
        // console.log(res.data)
        // console.log(res.status)
        // setData(res.data);
        if (res.status === 200) {
          Toast("Deleted Successfully", "success");
          handleDeleteModalClose();
        }
      })
      .catch((e) => {
        console.log(e);
        console.log(e.message);
        Toast("Something went wrong.", "error");
        // handleDeleteModalClose();
        // setIndicationMessage("Something went wrong, refresh again");
      });
  };

  const handleViewModalOpen = () => {
    setViewModal(true);
  };

  const handleViewModalClose = () => {
    setViewModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setViewModal(true);
  };

  function indication() {
    // return indicationMessage;
    return <ShimmerTable row={5} col={1} />;
  }

  const columns = [
    {
      headerStyle: {
        width: "80%",
        display: "none",
      },
      styles: {
        width: "80%",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex flex-column justify-content-start align-items-start p-2 ">
              <div className="d-flex flex-row justify-content-between w-100 mb-2">
                <UserAvatarWithNameAndTag
                  userName={row.user_name}
                  // userTag={"@user1234"}
                  avatarImage={row.user_image}
                />

                <Description
                  classes={`fs-8 gray-text`}
                  text={
                    <abbr
                      title={moment(row.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    >
                      {moment(row.createdAt).fromNow()}
                    </abbr>
                  }
                />
              </div>

              <Description
                text="Rating : "
                classes="fs-7 gray-text fw-bold my-0"
              />

              <ReactStars
                count={5}
                value={row.count}
                onChange={() => {}}
                size={30}
                edit={false}
                // activeColor="#ffd700"
                activeColor={primaryColor}
              />

              <Description
                text="Message :"
                classes="fs-7 gray-text fw-bold my-2"
              />

              <Description text={row.description} classes=" gray-text" />
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"User Feedbacks"} />
        </div>
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default UserFeedbackManagementScreen;
