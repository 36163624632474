import { createSlice } from "@reduxjs/toolkit";
import { postApi } from "../services/postApi";

const initialState = {
  postsReports: null,
};

export const postReportSlice = createSlice({
  name: "postReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        postApi.endpoints.getPostReports.matchFulfilled,
        (state, { payload }) => {
          state.postsReports = payload.data;
        }
      )
      .addMatcher(
        postApi.endpoints.getPostReports.matchRejected,
        (state, { payload }) => {
          state.postsReports = null;
        }
      );
  },
});

export const {} = postReportSlice.actions;

export default postReportSlice.reducer;
