import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/post_report_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
// import { useGetAllUsersQuery } from "../../Data/services/userApi"; // get data from API Servers

import { ShimmerTable } from "react-shimmer-effects";
import GlobalStyles from "../../_global.scss";
import DeleteModal from "../../Components/Modals/DeleteModal";
import ReportComponent from "../../Components/ReportComponent/ReportComponent";
import {
  useDeletePostReportMutation,
  useGetPostReportsQuery,
} from "../../Data/services/postReportApi";

const PostReportManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  const { primaryColor } = GlobalStyles;

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const dummyData = [
    {
      id: 1,
      reporter: {
        userName: "UserName",
        userTag: "@user1234",
        userImage: "images/user_image.png",
      },
      reportee: {
        post: {
          postTitle: "Devil Times Five (a.k.a. Peopletoys)",
          postCreated: "12/02/2026",
          user: {
            userName: "adranfield",
            userTag: "adranfield@1234",
            userImage: "images/user_image.png",
          },
        },
        message:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.",
        reportDate: "12/02/2025",
      },
    },
    {
      id: 2,
      reporter: {
        userName: "UserName 2",
        userTag: "@user1234",
        userImage: "images/user_image.png",
      },
      reportee: {
        post: {
          postTitle: "Devil Times Five (a.k.a. Peopletoys) Second",
          postCreated: "12/02/2026",
          user: {
            userName: "adranfield 2",
            userTag: "adranfield@1234",
            userImage: "images/user_image.png",
          },
        },
        message:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.",
        reportDate: "12/02/2025",
      },
    },
    {
      id: 3,
      reporter: {
        userName: "UserName 3",
        userTag: "@user1234",
        userImage: "images/user_image.png",
      },
      reportee: {
        post: {
          postTitle: "Devil Times Five (a.k.a. Peopletoys) Third",
          postCreated: "12/02/2026",
          user: {
            userName: "adranfield 3",
            userTag: "adranfield@1234",
            userImage: "images/user_image.png",
          },
        },
        message:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.",
        reportDate: "12/02/2025",
      },
    },
  ];

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetPostReportsQuery({ refetchOnMountOrArgChange: true });

  var userData = useSelector((state) => state.postReports.postsReports);
  // console.log(`userData = ${userData}`);
  useEffect(() => {
    setRowData(userData);
  }, [userData]);

  // useEffect(() => {
  //   if (data && isSuccess) {
  //     // console.log("isSuccess = " + data.users);
  //     dispatch(updateUsers(data.users));
  //   }
  // }, [isSuccess, data, dispatch]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const [deleteReport] = useDeletePostReportMutation();

  const deleteData = async (id) => {
    // console.log(editedData.slug);
    // console.log(id);

    // const postSlug = editedData.slug;

    try {
      const { data } = await deleteReport(id);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const handleShowDeleteModal = (row) => {
    // console.log(row);
    // debugger;
    setEditedData(row);
    handleDeleteModalOpen();
  };

  function indication() {
    // return indicationMessage;
    return <ShimmerTable row={5} col={1} />;
  }

  const columns = [
    {
      headerStyle: {
        width: "80%",
        display: "none",
      },
      styles: {
        width: "80%",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row) {
          return (
            <ReportComponent
              onClickDeleteButton={handleShowDeleteModal}
              data={row}
            />
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Post Report Management"} />
        </div>
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default PostReportManagementScreen;
