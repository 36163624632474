import { createSlice } from "@reduxjs/toolkit";
import { sportsListingApi } from "../services/sportsListingApi";

const initialState = {
  sportsListing: null,
};

export const sportsListingSlice = createSlice({
  name: "sportsListing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        sportsListingApi.endpoints.getSportsListing.matchFulfilled,
        (state, { payload }) => {
          state.sportsListing = payload.data;
        }
      )
      .addMatcher(
        sportsListingApi.endpoints.getSportsListing.matchRejected,
        (state, { payload }) => {
          state.sportsListing = null;
        }
      );
  },
});

export const {} = sportsListingSlice.actions;

export default sportsListingSlice.reducer;
