import React from "react";
import { Navigate } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import { getToken } from "../../Data/services/localStorageService";
import { baseRoute } from "../../Helper";

export default function PublicRoute({ children }) {
  var isLoggedIn = getToken(); //todo:

  // var isLoggedIn = useSelector((state) => state.authInfo.user);

  return isLoggedIn ? (
    <Navigate to={baseRoute(WEB_ROUTES.DASHBOARD)} replace />
  ) : (
    children
  );
}
