import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import { Description } from "../CustomComponents";
import UserAvatarWithNameAndTag from "../UserAvatar/UserAvatarWithNameAndTag";

const ReportComponent = ({ onClickDeleteButton = () => {}, data }) => {
  const styles = { border: "1px solid grey" };
  return (
    <div>
      <div className="d-flex flex-column justify-content-between align-items-start p-2 ">
        <div className="d-flex flex-row justify-content-start w-100 mb-2">
          <Row className="w-100">
            <Col sm={12} md={4}>
              <div className="rounded-4 py-2 px-3 w-100" style={styles}>
                <Description
                  text="Reporter :"
                  classes="fs-7 gray-text fw-bold mb-2"
                />
                <UserAvatarWithNameAndTag
                  userName={data.reporter_name}
                  // userTag={data.reporter.userTag}
                  avatarImage={data.reporter_image}
                />
              </div>
            </Col>
            {data ? (
              <Col sm={12} md={8}>
                <div className="rounded-4 py-2 px-3 w-100" style={styles}>
                  <Description
                    text="Reportee :"
                    classes="fs-7 text-danger fw-bold mb-2"
                  />
                  <Description
                    text={data.caption}
                    classes="fs-6 lc-1 fw-bold mb-2"
                  />
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <Description
                      text={`Post created at: ${moment(
                        data.post_createdAt
                      ).fromNow()}`}
                      classes="fs-8 gray-text fw-bold mb-2"
                    />
                    <UserAvatarWithNameAndTag
                      userName={data.reportee_name}
                      //   userTag={data.reportee.post.user.userTag}
                      avatarImage={data.reportee_image}
                    />
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </div>

        <Description
          classes={`fs-8 gray-text`}
          text={`Date: ${moment(data.createdAt).fromNow()}`}
        />

        <Description text="Message :" classes="fs-7 gray-text fw-bold my-2" />

        <Description text={data.reason} classes=" gray-text" />

        <div className="d-flex flex-row justify-content-end align-items-center w-100">
          <div
            className=" d-flex bg-danger rounded-3 p-2 mx-2 "
            role="button"
            onClick={() => onClickDeleteButton(data)}
          >
            <span className={`d-flex align-items-center pe-1`}>
              <MdDeleteForever />
            </span>
            <span>Delete</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportComponent;
