import { baseApi } from "./baseApi";
import { getToken } from "./localStorageService";

export const settingApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    // addStaticContent: builder.mutation({
    //   query: (data) => {
    //     return {
    //       url: "settings/create-content",
    //       method: "POST",
    //       body: data,
    //       headers: {
    //         "Content-type": "application/json; charset=UTF-8",
    //         authorization: `Bearer ${getToken()}`,
    //       },
    //     };
    //   },
    // }),
    updateStaticContent: builder.mutation({
      query: ({ modifiedData, slug }) => {
        console.log(slug);
        console.log(modifiedData);
        return {
          url: `settings/${slug}`,
          method: "PATCH",
          body: modifiedData,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      invalidatesTags: ["Static"],
    }),
    getTermsAndCondition: builder.query({
      query: () => {
        return {
          url: `settings/TermsAndCondition`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
      // providesTags: ["Static"],
    }),
    getPrivacyPolicy: builder.query({
      query: () => {
        return {
          url: `settings/PrivacyPolicy`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
      // providesTags: ["Static"],
    }),
    getContactUs: builder.query({
      query: () => {
        return {
          url: `settings/ContactUs`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
      // providesTags: ["Static"],
    }),
    changePassword: builder.mutation({
      query: (data) => {
        return {
          url: "change-password",
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
  }),
});

export const {
  // useAddStaticContentMutation,
  useChangePasswordMutation,
  useUpdateStaticContentMutation,
  useGetTermsAndConditionQuery,
  useGetPrivacyPolicyQuery,
  useGetContactUsQuery,
} = settingApi;
