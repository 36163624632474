import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUsers: (state, action) => {
      // console.log('update Users' + action.payload);
      state.users = action.payload;
    },
  },
});

export const { updateUsers } = userSlice.actions;

export default userSlice.reducer;
