import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FaCircleXmark } from "react-icons/fa6";
import {
  useDeleteTemplateMutation,
  useGetTemplateDetailsQuery,
} from "../../Data/services/templatesApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { Description } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/templateViewModal.module.scss";
const ViewTemplateModal = ({ handleModalClose, data, modalTitle }) => {
  const forumId = data.slug;
  const [templates, setTemplates] = useState([]);

  const {
    data: templateData,
    isSuccess,
    isLoading,
    isFetching,
    refetch,
  } = useGetTemplateDetailsQuery(forumId);

  useEffect(() => {
    if (templateData) {
      console.log(templateData.data);
      setTemplates(templateData.data);
    }
  }, [templateData]);

  const [deleteComment] = useDeleteTemplateMutation();

  const handleCommentDelete = async (slug) => {
    console.log(slug);

    try {
      const { data } = await deleteComment(slug);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  // testing
  // const templateData = {
  //   slug: "b750f710-84a1-4ec4-bb5e-c1cb61aead19",
  //   name: "test",
  //   description: "",
  //   calories: 12,
  //   duration: 12,
  //   is_paid: false,
  //   is_mine: false,
  //   price: 0,
  //   is_purchased: true,
  //   section: [
  //     {
  //       slug: "7f176d0c-cc9d-4cb1-838b-9a041d879058",
  //       title: "test",
  //       exercise: [
  //         {
  //           slug: "1fb1253b-1fdf-4606-a945-d06bd7bcb018",
  //           name: "test",
  //           intensity: "Easy Effort",
  //           standard_time: "12:10 AM",
  //           goal_time: "12:00 PM",
  //           notes: "test",
  //         },
  //       ],
  //     },
  //   ],
  // };

  return (
    <div>
      <ModalContainer handleClose={handleModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0"> View Template </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>

          {isFetching ? (
            <div className="w-100 text-light py-3 d-flex justify-content-center">
              <Description
                classes={`w-100 py-3 text-white`}
                text="Loading..."
              />
            </div>
          ) : (
            <>
              <h3>{templates.name}</h3>

              <table
                className={`${cssStyles.tableBg}`}
                style={{
                  width: "50%",
                  padding: "6px",
                  borderRadius: "20px",
                  backgroundColor: "#26262E",
                  borderCollapse: "separate",
                }}
              >
                <tr
                  style={{ width: "100%" }}
                  className={`${cssStyles.tableBg}`}
                >
                  <td
                    style={{
                      width: "50%",
                      textAlign: "center",
                      backgroundColor: "#26262E",
                    }}
                  >
                    <br></br>
                    <h4 style={{ fontSize: "28px", fontWeight: "bold" }}>
                      {templates.duration}
                    </h4>
                    <p>mins</p>
                  </td>

                  <td
                    style={{
                      width: "50%",
                      textAlign: "center",
                      backgroundColor: "#26262E",
                    }}
                  >
                    <br></br>
                    <h4 style={{ fontSize: "28px", fontWeight: "bold" }}>
                      {templates.calories}
                    </h4>
                    <p>Kcal</p>
                  </td>
                </tr>
              </table>

              <br></br>
              {_.isEmpty(templates?.section) ? (
                isFetching ? (
                  <div className="w-100 text-light py-3 d-flex justify-content-center">
                    <Description
                      classes={`w-100 py-3 text-white`}
                      text="Loading..."
                    />
                  </div>
                ) : (
                  <Description
                    classes={`w-100 py-3 text-white`}
                    text="No template sections found"
                  />
                )
              ) : (
                <div class={`${cssStyles.contentData} mb-5`}>
                  {(templates.section ?? [])?.map((item) => {
                    return (
                      <div
                        class={`${cssStyles.sectionContainer} my-3`}
                        index={item.slug}
                      >
                        <h3 class="fw-bold mb-3"> {item.title} </h3>
                        {(item.exercise ?? [])?.map((exercise) => {
                          return (
                            <div
                              class={`${cssStyles.exerciseContainer} ps-1`}
                              key={exercise.slug}
                            >
                              <table
                                style={{
                                  width: "100%",
                                  marginTop: "8px",
                                  borderCollapse: "separate",
                                }}
                              >
                                <tr style={{ width: "100%" }}>
                                  <td style={{ width: "fit-content" }}>
                                    <h5 class="fst-normal align-items m-0">
                                      {exercise.name}
                                      <span
                                        class="badge ms-3 fs-6 fw-normal"
                                        style={{ backgroundColor: "#74C772" }}
                                      >
                                        {exercise.intensity}
                                      </span>
                                    </h5>
                                  </td>
                                </tr>
                              </table>

                              <table
                                style={{
                                  width: "100%",
                                  marginTop: "8px",
                                  borderCollapse: "separate",
                                }}
                              >
                                <tr style={{ width: "100%" }}>
                                  <td style={{ width: "50%" }}>
                                    <h5 class="fst-normal align-items m-0">
                                      Standard Time
                                    </h5>
                                  </td>
                                  <td
                                    style={{ width: "50%", textAlign: "right" }}
                                  >
                                    <span class="ms-auto  align-items-center">
                                      <img
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAA1CAYAAAA6XQlSAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOkSURBVHgB7VrRcdswDIUVJ79JJqg7QbxB3QnqDZpukE5Qd4K2E7idIMkEUSaIN5A2cP5yZ8t2H2w6lWBIlARa19713fFiWxD5CIIACIbIiMVicZ1l2Rxt42kJ2kcyokdGrFarZLPZDGqKP/f7/UsyICIjQPaigfgzGWEmvF6vv9YUfe71et/JCLNJSLC95r/DBIKOYdZw1/hP+NjokwHsIWCzwyiKrvB5iE01xN+CDJ4/0G7DzbBBH09PT2MyoNWGWC6XIxDgIDBGa+LWGOza7jCxX1byXjBR1liNqFa3lUY/HgtB6Qsi6ZCagiNZYKKyTV9eXgb78Vy4f33O5PfPvCaBF3jZp1S+9LzEMUwkhi2n+DyjP1q6xLKfw3bZtkf4aVTRD787gWwP/UzzD9D35OTkxB+gMOCkQisPIPGuYWhmBVy3WC1/0lRGFnb1xETJCO6DEycfWbZjb2cVZL9RYDAhE1m3ZPLleRYgl60Yc5zpOXX1mM4byBfnrVxLQ/AY2th573EACNwqsxxTR3CaPtjcZcLXrWwoMHifSB55P5wXTIRgQkcABwU3VqIS2XF5qtSyC7mFWYVwXRpZaaOanManMDnMaFrLbsKSLSXMUILLjpNLEaV2P1BAlJCt3COKlufbqKo8CGq7bcjuId0cc0WeEUltzigQmKxMZBh1kxnI3eW/M9eITwpCKKYAsJJ1KChvW7CRLiSEd7CYgehneGCu0k6AN2RAKLIMlyoUvYriIZqe0QrQUsa2EVMhvAl+zJcTbmizXjBhWaA7JwNytTY+2t+EJLuFXMIQqSRr2WpaDC1GsFsruA4uipAR0Cxr115aPeSSRug4FT+O6O+BjBEz1vCjEOosYa+BUf4LNB6ryU9ZntolSlLei8jZWpwXxm+dnzQkoE15AI1f94U3Ye4YWsDI5AlaiVDBk/i6UI5IhylvphxC4ZNvqGPwmF7t7qEcSzZd1CRyZIcK2cT3gixmJJXFjEBgu9USJ+/YJUuSHFPT3Lcpy9OKGceyaaegeWuye+ClnxpptNsQJlJV1eeyA7VBmaZdm7bx1c7n35b16yvpeq8MeGmgjUmFSEq7SHmPXDjFFUG6j0gcSkFwgKjFV2KceY2rbv5d/vyDLITdwAOQeWjwbwZNEaP/z2dnZ94SQ60jEqegmPlbfPykpKMWbE8luEB/X4dsa2TtLlYKYb9tOcz0rwHO2fOt6JUryHCTh9CU3NUYbJm1eB/iNPLP4Dcv5LzO882EvAAAAABJRU5ErkJggg=="
                                        width="16"
                                        height="18"
                                        class="me-2"
                                      />
                                      {exercise.standard_time}
                                    </span>
                                  </td>
                                </tr>
                              </table>

                              <table
                                style={{
                                  width: "100%",
                                  marginTop: "8px",
                                  borderCollapse: "separate",
                                }}
                              >
                                <tr style={{ width: "100%" }}>
                                  <td style={{ width: "50%" }}>
                                    <h5 class="fst-normal align-items m-0">
                                      Goal Time
                                    </h5>
                                  </td>
                                  <td
                                    style={{ width: "50%", textAlign: "right" }}
                                  >
                                    <span class="ms-auto  align-items-center">
                                      <img
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAA1CAYAAAA6XQlSAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOkSURBVHgB7VrRcdswDIUVJ79JJqg7QbxB3QnqDZpukE5Qd4K2E7idIMkEUSaIN5A2cP5yZ8t2H2w6lWBIlARa19713fFiWxD5CIIACIbIiMVicZ1l2Rxt42kJ2kcyokdGrFarZLPZDGqKP/f7/UsyICIjQPaigfgzGWEmvF6vv9YUfe71et/JCLNJSLC95r/DBIKOYdZw1/hP+NjokwHsIWCzwyiKrvB5iE01xN+CDJ4/0G7DzbBBH09PT2MyoNWGWC6XIxDgIDBGa+LWGOza7jCxX1byXjBR1liNqFa3lUY/HgtB6Qsi6ZCagiNZYKKyTV9eXgb78Vy4f33O5PfPvCaBF3jZp1S+9LzEMUwkhi2n+DyjP1q6xLKfw3bZtkf4aVTRD787gWwP/UzzD9D35OTkxB+gMOCkQisPIPGuYWhmBVy3WC1/0lRGFnb1xETJCO6DEycfWbZjb2cVZL9RYDAhE1m3ZPLleRYgl60Yc5zpOXX1mM4byBfnrVxLQ/AY2th573EACNwqsxxTR3CaPtjcZcLXrWwoMHifSB55P5wXTIRgQkcABwU3VqIS2XF5qtSyC7mFWYVwXRpZaaOanManMDnMaFrLbsKSLSXMUILLjpNLEaV2P1BAlJCt3COKlufbqKo8CGq7bcjuId0cc0WeEUltzigQmKxMZBh1kxnI3eW/M9eITwpCKKYAsJJ1KChvW7CRLiSEd7CYgehneGCu0k6AN2RAKLIMlyoUvYriIZqe0QrQUsa2EVMhvAl+zJcTbmizXjBhWaA7JwNytTY+2t+EJLuFXMIQqSRr2WpaDC1GsFsruA4uipAR0Cxr115aPeSSRug4FT+O6O+BjBEz1vCjEOosYa+BUf4LNB6ryU9ZntolSlLei8jZWpwXxm+dnzQkoE15AI1f94U3Ye4YWsDI5AlaiVDBk/i6UI5IhylvphxC4ZNvqGPwmF7t7qEcSzZd1CRyZIcK2cT3gixmJJXFjEBgu9USJ+/YJUuSHFPT3Lcpy9OKGceyaaegeWuye+ClnxpptNsQJlJV1eeyA7VBmaZdm7bx1c7n35b16yvpeq8MeGmgjUmFSEq7SHmPXDjFFUG6j0gcSkFwgKjFV2KceY2rbv5d/vyDLITdwAOQeWjwbwZNEaP/z2dnZ94SQ60jEqegmPlbfPykpKMWbE8luEB/X4dsa2TtLlYKYb9tOcz0rwHO2fOt6JUryHCTh9CU3NUYbJm1eB/iNPLP4Dcv5LzO882EvAAAAABJRU5ErkJggg=="
                                        width="16"
                                        height="18"
                                        class="me-2"
                                      />
                                      {exercise.goal_time}
                                    </span>
                                  </td>
                                </tr>
                              </table>

                              {!exercise?.notes ? (
                                ""
                              ) : (
                                <table
                                  style={{
                                    width: "100%",
                                    marginTop: "8px",
                                    borderCollapse: "separate",
                                  }}
                                >
                                  <tr style={{ width: "100%" }}>
                                    <td style={{ width: "15%" }}>
                                      <h5 class="fst-normal align-items m-0">
                                        {" "}
                                        Note :
                                      </h5>
                                    </td>
                                    <td
                                      style={{
                                        width: "85%",
                                        textAlign: "right",
                                      }}
                                    >
                                      <span class="fst-italic ms-2">
                                        {exercise.notes}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              )}

                              <div class={`${cssStyles.extraAfter}`}></div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </ModalContainer>
    </div>
  );
};

export default ViewTemplateModal;
