import { baseApi } from "./baseApi";
import { getToken } from "./localStorageService";

export const notificationApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    sendNotification: builder.mutation({
      query: (data) => {
        return {
          url: "notification",
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
  }),
});

export const { useSendNotificationMutation } = notificationApi;
