import React from "react";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import BeatLoader from "react-spinners/BeatLoader";
import {
  Button,
  CustomInput,
  Description,
  SubTitle,
  UnorderedList,
} from "../../Components/CustomComponents";
import { useChangePasswordMutation } from "../../Data/services/settingApi";
import Toast from "../../Hooks/useToast";
import styles from "./styles/ChangePassword.module.scss";

export default function ChangePassword() {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({});

  // Watch the current_password field value to compare with new_password
  const current_password = watch("current_password");

  // Watch the new_password field value to compare with confirm_password
  const new_password = watch("new_password");

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const handleDataSubmission = async (userData) => {
    console.log(userData);
    try {
      // Use unwrap() to get the response userData directly
      const response = await changePassword(userData);
      console.log(response);

      if (response.error && response.error?.data?.code === 400) {
        Toast(response.error?.data?.message, "error");
      }

      if (!response.error && response.data.code === 200) {
        Toast(response?.data?.message, "success");
      }
    } catch (error) {
      console.log(error);

      if (error?.data && error?.data?.message) {
        Toast(error?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };
  return (
    <div className={styles.ChangePassword}>
      {errors
        ? errors.current_password?.message
          ? Toast(errors.current_password?.message, "error")
          : errors.new_password?.message
          ? Toast(errors.new_password?.message, "error")
          : errors.confirm_password?.message
          ? Toast(errors.confirm_password?.message, "error")
          : null
        : null}
      <SubTitle text="Change Password" />
      <Description
        text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        classes="fs-8 mb-4"
      />
      <Form onSubmit={handleSubmit(handleDataSubmission)}>
        <div className={styles.form}>
          <Description text="Current Password" classes="mb-1" />
          <Controller
            control={control}
            name="current_password"
            rules={{ required: "Current Password is required." }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Current Password"
                type="password"
                onChange={onChange}
              />
            )}
          />
          <Description text="Set New Password" classes="mt-3 mb-1" />
          <Controller
            control={control}
            name="new_password"
            rules={{
              required: "New Password is required.",
              validate: (value) =>
                value === current_password ||
                "New Password must be different from current password.",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="New Password"
                type="password"
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="confirm_password"
            rules={{
              required: "Confirm Password is required.",
              validate: (value) =>
                value === new_password ||
                "New Password must be match with confirm password.",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Confirm Password"
                type="password"
                onChange={onChange}
              />
            )}
          />
          <UnorderedList
            lists={[
              "At least 12 characters long but 14 or more is better.",
              "A combination of uppercase letters, lowercase letters, numbers, and symbols.",
            ]}
            classes="fs-6 mt-4 mb-4"
          />

          <div className={styles.saveButton}>
            <Button
              text={isLoading ? <BeatLoader size={10} /> : "Change Password"}
              classes={`fs-6 mb-2 mt-auto mt-3 mb-2 ${
                isLoading ? "bg-dark-subtle" : ""
              }`}
              disabled={isLoading}
              type="submit"
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
