import { createSlice } from "@reduxjs/toolkit";
import { forumApi } from "../services/forumApi";

const initialState = {
  forum: null,
};

export const forumSlice = createSlice({
  name: "forum",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        forumApi.endpoints.getAllForums.matchFulfilled,
        (state, { payload }) => {
          state.forum = payload.data;
        }
      )
      .addMatcher(
        forumApi.endpoints.getAllForums.matchRejected,
        (state, { payload }) => {
          state.forum = null;
        }
      );
  },
});

export const {} = forumSlice.actions;

export default forumSlice.reducer;
