import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, SubTitle } from "../../Components/CustomComponents";
import TextEditor from "../../Components/TextEditor/TextEditor";
import { useUpdateStaticContentMutation } from "../../Data/services/settingApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/StaticPage.module.scss";

export default function PrivacyPolicy({ refetch }) {
  //   const [value, setValue] = useState(
  //     "<h1>Privacy Policy</h1><h2>What is Lorem Ipsum?</h2><p><br></p><p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p > <p><br></p>"
  //   );
  const [value, setValue] = useState(
    "<h1>Privacy Policy</h1><h2>What is Lorem Ipsum?</h2><p><br></p><p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p > <p><br></p>"
  );

  var privacyPolicyData = useSelector((state) => state.setting.privacy_policy);

  console.log(privacyPolicyData);

  useEffect(() => {
    if (privacyPolicyData) {
      setValue(privacyPolicyData.text);
    }
  }, [privacyPolicyData]);

  const [updatePrivacy, { isLoading }] = useUpdateStaticContentMutation();

  const handleSaveText = async () => {
    // console.log(value)
    // console.log(value);
    //   console.log(privacyPolicyData.slug);

    const slug = privacyPolicyData.slug;
    const modifiedData = await {
      text: value,
    };

    console.log(modifiedData);
    try {
      const { data } = await updatePrivacy({ modifiedData, slug });
      console.log(data);

      if (data.code === 200) {
        refetch();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };
  return (
    <div className={styles.StaticPage}>
      <Row>
        <Col sm={10}>
          <SubTitle text="Privacy Policy" classes="mb-4" />
        </Col>
        <Col sm={2}>
          <Button
            classes={`${styles.cardBtn} ${isLoading ? "bg-dark-subtle" : ""}`}
            text={isLoading ? <BeatLoader size={10} /> : "Save"}
            onClick={handleSaveText}
            disabled={isLoading}
          ></Button>
        </Col>
      </Row>
      {<TextEditor text={value} onChange={setValue} />}
    </div>
  );
}
