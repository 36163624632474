import { createSlice } from "@reduxjs/toolkit";
import { settingApi } from "../services/settingApi";

const initialState = {
  contact_us: null,
  terms_condition: null,
  privacy_policy: null,
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        settingApi.endpoints.getTermsAndCondition.matchFulfilled,
        (state, { payload }) => {
          state.terms_condition = payload.data;
        }
      )
      .addMatcher(
        settingApi.endpoints.getTermsAndCondition.matchRejected,
        (state, { payload }) => {
          state.terms_condition = null;
        }
      )
      .addMatcher(
        settingApi.endpoints.getPrivacyPolicy.matchFulfilled,
        (state, { payload }) => {
          state.privacy_policy = payload.data;
        }
      )
      .addMatcher(
        settingApi.endpoints.getPrivacyPolicy.matchRejected,
        (state, { payload }) => {
          state.privacy_policy = null;
        }
      )
      .addMatcher(
        settingApi.endpoints.getContactUs.matchFulfilled,
        (state, { payload }) => {
          state.contact_us = payload.data;
        }
      )
      .addMatcher(
        settingApi.endpoints.getContactUs.matchRejected,
        (state, { payload }) => {
          state.contact_us = null;
        }
      );
  },
});

export const {} = settingSlice.actions;

export default settingSlice.reducer;
