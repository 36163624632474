import { baseApi } from "./baseApi";
import { getToken } from "./localStorageService";

export const trainingLogApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    getLogs: builder.query({
      query: () => {
        return {
          url: `logs`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
      // providesTags: ["Static"],
    }),
  }),
});

export const { useGetLogsQuery } = trainingLogApi;
