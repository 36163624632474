import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import { useUpdateStaticContentMutation } from "../../Data/services/settingApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/StaticPage.module.scss";

const ContactUs = ({ refetch }) => {
  const [adminEmail, setAdminEmail] = useState("");

  var contactData = useSelector((state) => state.setting.contact_us);

  console.log(contactData);
  useEffect(() => {
    if (contactData) {
      setAdminEmail(contactData.text);
    }
  }, [contactData]);

  const [updateContact, { isLoading }] = useUpdateStaticContentMutation();

  const handleAdminEmail = (e) => {
    // console.log(e.target.value);
    setAdminEmail(e.target.value);
  };

  const handleSaveText = async () => {
    // console.log(value
    console.log(adminEmail);
    const slug = contactData.slug;
    const modifiedData = await {
      text: adminEmail,
    };

    console.log(modifiedData);
    try {
      const { data } = await updateContact({ modifiedData, slug });
      console.log(data);

      if (data.code === 200) {
        refetch();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  return (
    <div className={styles.StaticPage}>
      <Row>
        <Col sm={10}>
          <SubTitle text="Contact Us" classes="mb-4" />
        </Col>
        <Col sm={2}>
          <Button
            classes={`${styles.cardBtn} ${isLoading ? "bg-dark-subtle" : ""}`}
            text={isLoading ? <BeatLoader size={10} /> : "Save"}
            onClick={handleSaveText}
            disabled={isLoading}
          ></Button>
        </Col>
      </Row>
      <p className="text-white">
        Set your email, App user will contact you on this email.
      </p>
      <Row>
        <Col md={12}>
          <p className="m-0 text-white">Email</p>
          <Input
            classes="mt-2 mb-3"
            type="email"
            placeholder="Enter Admin Email"
            value={adminEmail}
            onChange={handleAdminEmail}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
