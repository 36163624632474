import { createSlice } from "@reduxjs/toolkit";
import { userAuthApi } from "../services/userAuthApi";

const initialState = {
  user: null,
};

export const authSlice = createSlice({
  name: "authInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userAuthApi.endpoints.loginUser.matchFulfilled,
        (state, { payload }) => {
          state.user = payload.data;
        }
      )
      .addMatcher(
        userAuthApi.endpoints.loginUser.matchRejected,
        (state, { payload }) => {
          state.user = null;
        }
      )
      .addMatcher(
        userAuthApi.endpoints.logoutUser.matchFulfilled,
        (state, { payload }) => {
          state.user = null;
        }
      );
  },
});

export const { setAuthInfo, unsetAuthInfo } = authSlice.actions;

export default authSlice.reducer;
