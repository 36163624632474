import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaEye } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { Description, SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
// import { useGetAllUsersQuery } from "../../Data/services/userApi"; // get data from API Servers

import moment from "moment";
import { ShimmerTable } from "react-shimmer-effects";
import ViewTemplateModal from "../../Components/Modals/ViewTemplateModal";
import {
  useDeleteTemplateMutation,
  useGetAllTemplatesQuery,
} from "../../Data/services/templatesApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";

const TemplateManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const dummyData = [
    {
      id: 1,
      post_title: "Devil Times Five (a.k.a. Peopletoys)",
      image_url: "https://www.youtube.com/watch?v=HJFxTsX0Ok0",
      user_name: "adranfield0",
      date: "6/29/2023",
    },
    {
      id: 2,
      post_title: "Young Lions, The",
      image_url: "https://www.youtube.com/watch?v=HJFxTsX0Ok0",
      user_name: "milett1",
      date: "2/14/2024",
    },
    {
      id: 3,
      post_title: "Deep Red (Profondo rosso)",
      image_url: "https://www.youtube.com/watch?v=HJFxTsX0Ok0",
      user_name: "dtomblett2",
      date: "12/15/2023",
    },
    {
      id: 4,
      post_title: "Hunchback of Notre Dame, The",
      image_url: "Drama|Horror",
      user_name: "jmassimi3",
      date: "2/19/2024",
    },
    {
      id: 5,
      post_title: "Dahmer",
      image_url: "Drama|Horror|Thriller",
      user_name: "bposen4",
      date: "10/19/2023",
    },
    {
      id: 6,
      post_title: "London",
      image_url: "Documentary",
      user_name: "ivany5",
      date: "10/21/2023",
    },
    {
      id: 7,
      post_title: "Thief of Bagdad, The",
      image_url: "Action|Adventure|Fantasy",
      user_name: "cstaniforth6",
      date: "8/6/2023",
    },
    {
      id: 8,
      post_title: "Grass Harp, The",
      image_url: "Comedy|Drama",
      user_name: "rgownge7",
      date: "11/5/2023",
    },
    {
      id: 9,
      post_title: "Goldfish Memory",
      image_url: "Comedy|Drama",
      user_name: "mrosle8",
      date: "12/5/2023",
    },
    {
      id: 10,
      post_title: "Not as a Stranger",
      image_url: "Drama",
      user_name: "mkubas9",
      date: "2/3/2024",
    },
    {
      id: 11,
      post_title: "Ballou",
      image_url: "Documentary|Musical",
      user_name: "gmckoya",
      date: "3/26/2024",
    },
    {
      id: 12,
      post_title: "Tumannost Andromedy",
      image_url: "Adventure|Romance|Sci-Fi",
      user_name: "smatyukonb",
      date: "12/21/2023",
    },
    {
      id: 13,
      post_title: "Vivien Leigh: Scarlett and Beyond",
      image_url: "Documentary",
      user_name: "leaglestonc",
      date: "2/18/2024",
    },
    {
      id: 14,
      post_title: "Machuca",
      image_url: "Drama",
      user_name: "bcuddehayd",
      date: "11/7/2023",
    },
    {
      id: 15,
      post_title: "Amuck!",
      image_url: "Drama|Horror|Mystery",
      user_name: "ewestropee",
      date: "7/22/2023",
    },
    {
      id: 16,
      post_title: "Breakout",
      image_url: "Action|Adventure",
      user_name: "igongf",
      date: "7/24/2023",
    },
    {
      id: 17,
      post_title: "Zero Effect",
      image_url: "Comedy|Mystery|Thriller",
      user_name: "epetrollig",
      date: "4/29/2024",
    },
    {
      id: 18,
      post_title: "Finding Neverland",
      image_url: "Drama",
      user_name: "zfoxallh",
      date: "12/12/2023",
    },
    {
      id: 19,
      post_title: "Battle of the Rails, The (La bataille du rail)",
      image_url: "Drama|War",
      user_name: "kpittendreighi",
      date: "9/12/2023",
    },
    {
      id: 20,
      post_title: "Line King: The Al Hirschfeld Story, The",
      image_url: "Documentary",
      user_name: "cnovotnij",
      date: "7/5/2023",
    },
  ];

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllTemplatesQuery({ refetchOnMountOrArgChange: true });

  var forumData = useSelector((state) => state.templates.templates);
  // console.log(`forumData = ${forumData}`);

  useEffect(() => {
    setRowData(forumData);
  }, [forumData]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const [deleteTemplate] = useDeleteTemplateMutation();

  const deleteData = async (id) => {
    // console.log(editedData.slug);
    // console.log(id);

    try {
      const { data } = await deleteTemplate(id);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const handleViewModalOpen = () => {
    setViewModal(true);
  };

  const handleViewModalClose = () => {
    setViewModal(false);
    setEditedData("");
  };

  function indication() {
    // return indicationMessage;
    return <ShimmerTable row={5} col={3} />;
  }

  const columns = [
    {
      headerStyle: {
        width: "80%",
        display: "none",
      },
      styles: {
        width: "80%",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex flex-column justify-content-start align-items-start p-2 ">
              <div className="d-flex flex-row justify-content-between w-100 mb-2">
                {/* <UserAvatarWithNameAndTag */}
                {/* // userName={row.name} */}
                {/* // userTag={"@user1234"} */}
                {/* // avatarImage={row.user_name} */}
                {/* /> */}

                <Description
                  classes={`fs-8 gray-text`}
                  text={`Published date: ${moment(row.createdAt).format(
                    "MMMM Do YYYY"
                  )}`}
                />
              </div>

              <div className="d-flex flex-row justify-content-start gap-4 w-100">
                {/* {row.media_url ? (
                  <img
                    src={row.media_url}
                    alt=""
                    width="200px"
                    style={{ borderRadius: "25px" }}
                  />
                ) : null} */}

                <Description text={row.name} classes="fs-4 lc-1" />
                <div
                  style={{
                    backgroundColor: "#26262E",
                    padding: "6px",
                    borderRadius: "18px",
                    display: "flex",
                    width: "300px",
                    justifyContent: "space-around",
                  }}
                >
                  <Description
                    text={` ${row.duration} mins`}
                    classes="fs-5 lc-1"
                  />
                  <Description
                    text={` ${row.calories} Kcal`}
                    classes="fs-5 lc-1"
                  />
                </div>
                {/* <Description
                    text={row.description}
                    classes=" gray-text lc-2"
                  /> */}
              </div>

              <div className="d-flex flex-row justify-content-between align-items-center w-100 mt-3">
                <Description text={` `} classes="fs-7 primary-text fw-bold" />

                <div className="d-flex ">
                  <div
                    className={`${styles.btnBg} d-flex justify-content-center align-items-center rounded-3 mx-2 p-2 `}
                    role="button"
                    onClick={() => {
                      setEditedData(row);
                      handleViewModalOpen(true);
                    }}
                  >
                    <span
                      className={`align-items-center d-flex justify-content-center pe-1 `}
                    >
                      <FaEye />
                    </span>
                    <span>View</span>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center bg-danger rounded-3 p-2 mx-2 "
                    role="button"
                    onClick={() => {
                      setEditedData(row);
                      handleDeleteModalOpen();
                    }}
                  >
                    <span className={`d-flex align-items-center pe-1`}>
                      <MdDeleteForever />
                    </span>
                    <span>Delete</span>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
        />
      ) : (
        ""
      )}

      {viewModal ? (
        <ViewTemplateModal
          handleModalClose={handleViewModalClose}
          data={editedData}
          modalTitle="View Forum Thread"
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Template Management"} />
        </div>
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default TemplateManagementScreen;
