import { createSlice } from "@reduxjs/toolkit";
import { trainingLogApi } from "../services/trainingLogApi";

const initialState = {
  logs: null,
};

export const trainingLogSlice = createSlice({
  name: "trainingLog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        trainingLogApi.endpoints.getLogs.matchFulfilled,
        (state, { payload }) => {
          state.logs = payload.data;
        }
      )
      .addMatcher(
        trainingLogApi.endpoints.getLogs.matchRejected,
        (state, { payload }) => {
          state.logs = null;
        }
      );
  },
});

export const {} = trainingLogSlice.actions;

export default trainingLogSlice.reducer;
